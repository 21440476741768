import { Button, Col, Descriptions, Divider, Empty, Form, Modal, Row, Space, Spin, Tag, Typography } from "antd"
import { FunctionComponent, useEffect, useState } from "react"
import { Vector } from "../../assets/svg/Index"
import { PaginationModel } from "../../models/PaginationModel"
import { TrademarkModel } from "../../models/TrademarkModel"
import { GoodsService } from "../../services/GoodsService"
import { InputForm } from "../form/InputForm"

interface IProps {
    open: boolean
    codes?: Array<string>
    지정상품?: Array<TrademarkModel.ITrademarkModel지정상품Model>
    onPress: (지정상품: Array<TrademarkModel.ITrademarkModel지정상품Model>) => void
    onClosed: () => void
}

export const 지정상품입력Modal: FunctionComponent<IProps> = (props) => {
    const {
        open,
        codes,
        지정상품,
        onPress,
        onClosed,
    } = props

    const [isLoading, setLoading] = useState<boolean>(false)
    const [pages, setPages] = useState<PaginationModel.IPaginationModel<TrademarkModel.ITrademarkModel지정상품Model>>()
    const [items, setItems] = useState<Array<TrademarkModel.ITrademarkModel지정상품Model>>([])
    const [selecteds, setSelecteds] = useState<Array<TrademarkModel.ITrademarkModel지정상품Model>>([])
    const [form] = Form.useForm()

    const search = async (page: number = 0) => {
        setLoading(true)
        const response = await GoodsService.search(
            page,
            {
                keyword: form.getFieldValue('keyword'),
                ClassificationCode: codes?.join(',')
            }
        )
        if (response.status === 200) {
            setPages(response.data)

            if (page === 0) {
                setItems([...response.data.items])
            } else {
                setItems([...items, ...response.data.items])
            }
        }
        setLoading(false)
    }

    useEffect(() => {
        if (open) {
            setSelecteds(지정상품 ?? [])
        }
    }, [open])

    const Footer = () => {
        return (
            <Row style={{ position: 'absolute', bottom: 0, right: 0, width: '100%', height: '32px' }}>
                <Col span={24} style={{ textAlign: 'end' }}>
                    <Space>
                        <Button
                            type="default"
                            onClick={onClosed}
                        >
                            취소
                        </Button>
                        <Button
                            className="primary small"
                            onClick={() => {
                                onPress(selecteds ?? [])
                            }}
                        >
                            등록
                        </Button>
                    </Space>
                </Col>
            </Row>
        )
    }

    return (
        <Modal
            open={open}
            destroyOnClose={true}
            title={'지정상품 정보'}
            onCancel={() => { onClosed() }}
            footer={false}
        >
            <Form form={form}>
                <Row gutter={[12, 12]} style={{ marginTop: 20, }}>
                    {selecteds?.map((s, idx) => {
                        return (
                            <Col key={idx} span={24}>
                                <Descriptions
                                    bordered
                                    column={1}
                                    size="small"
                                    labelStyle={{ width: 100, }}
                                >
                                    <Descriptions.Item label={'상품류'}>
                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <Typography.Text>{s.classificationCode}</Typography.Text>
                                            <Button
                                                danger
                                                ghost
                                                size="small"
                                                onClick={() => {
                                                    setSelecteds([...selecteds.filter(i => i !== s)])
                                                }}
                                            >
                                                해제
                                            </Button>
                                        </div>
                                    </Descriptions.Item>
                                    <Descriptions.Item label={'명칭(한글)'}>{s.koreanText}</Descriptions.Item>
                                    <Descriptions.Item label={'명칭(영문)'}>{s.englishText}</Descriptions.Item>
                                    <Descriptions.Item label={'유사군'}>
                                        <Space size={2} wrap>
                                            {s.similarCodes?.split(',')?.map((sc) => {
                                                return (
                                                    <Tag key={sc}>{sc}</Tag>
                                                )
                                            })}
                                        </Space>
                                    </Descriptions.Item>
                                </Descriptions>
                            </Col>
                        )
                    })}
                </Row>
                {selecteds?.length > 0 && (
                    <Divider style={{ borderBlockStartWidth: 2 }} />
                )}
                <Spin spinning={isLoading}>
                    <Row gutter={[12, 12]}>
                        <Col span={24} style={{ marginTop: 0, }}>
                            <Row justify={'center'}>
                                <Col span={24}>
                                    <InputForm
                                        name='keyword'
                                        placeholder='상품/서비스명을 입력하세요'
                                        style={{
                                            height: 50,
                                            borderRadius: 30,
                                            fontSize: 16,
                                            paddingLeft: 30,
                                        }}
                                        layout={{ lg: { span: 24 }, sm: { span: 24 }, xs: { span: 24 } }}
                                        onPressEnter={search}
                                    />
                                    <Typography.Link onClick={() => { search() }}>
                                        <img
                                            src={Vector.icon.search.gray} width={30}
                                            style={{
                                                position: 'absolute',
                                                top: 10,
                                                right: 20,
                                                zIndex: 10,
                                            }}
                                            alt='검색'
                                        />
                                    </Typography.Link>
                                </Col>
                            </Row>
                        </Col>
                        {items.length > 0 && (
                            <Col span={24} style={{ marginBottom: 40, minHeight: 360, maxHeight: 560, overflowY: 'scroll' }}>
                                <Row gutter={[12, 12]} >
                                    {items
                                        .filter(s => !selecteds.includes(s))
                                        .map((s, idx: number) => {
                                            return (
                                                <Col span={24} key={idx}>
                                                    <Descriptions
                                                        bordered
                                                        column={1}
                                                        size="small"
                                                        labelStyle={{ width: 100, }}
                                                    >
                                                        <Descriptions.Item label={'상품류'}>
                                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                <Typography.Text>{s.classificationCode}</Typography.Text>
                                                                <Button
                                                                    ghost
                                                                    type="primary"
                                                                    size="small"
                                                                    onClick={() => {
                                                                        setSelecteds([...selecteds, s])
                                                                    }}
                                                                >
                                                                    선택
                                                                </Button>
                                                            </div>
                                                        </Descriptions.Item>
                                                        <Descriptions.Item label={'명칭(한글)'}>{s.koreanText}</Descriptions.Item>
                                                        <Descriptions.Item label={'명칭(영문)'}>{s.englishText}</Descriptions.Item>
                                                        <Descriptions.Item label={'유사군'}>
                                                            <Space size={2} wrap>
                                                                {s.similarCodes?.split(',')?.map((sc) => {
                                                                    return (
                                                                        <Tag key={sc}>{sc}</Tag>
                                                                    )
                                                                })}
                                                            </Space>
                                                        </Descriptions.Item>
                                                    </Descriptions>
                                                </Col>
                                            )
                                        })}
                                    <Col span={24}>
                                        {pages?.hasNextResult && (
                                            <Col span={24} style={{ marginTop: 20, textAlign: 'center', }}>
                                                <Button
                                                    loading={isLoading}
                                                    style={{ width: '50%', }}
                                                    onClick={() => {
                                                        search(pages.nextPageIndex)
                                                    }}
                                                >
                                                    더보기
                                                </Button>
                                            </Col>
                                        )}
                                    </Col>
                                </Row>
                            </Col>
                        )}
                        {!isLoading && (items ?? []).length <= 0 && (
                            <Col span={24} style={{ marginBottom: 40, }}>
                                <Empty
                                    imageStyle={{ height: 0 }}
                                    description={
                                        <Space direction="vertical">
                                            <Typography.Text>
                                                검색 결과가 없습니다,
                                            </Typography.Text>
                                            <Typography.Text>
                                                다른 <Typography.Text strong>명칭</Typography.Text>으로 검색해 주세요!
                                            </Typography.Text>
                                        </Space>
                                    }
                                />
                            </Col>
                        )}
                        <Col span={24}>
                            <Footer />
                        </Col>
                    </Row>
                </Spin>
            </Form>

        </Modal>
    )
}