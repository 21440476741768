import { AxiosRequestConfig } from "axios"
import AxiosContext from "../contexts/AxiosContext"

export const GoodsService = {
    search: async (page: number = 0, model: any) => {
        try {
            const config: AxiosRequestConfig = {
                params: { pageIndex: page, ...model }
            }

            const { data, status } = await AxiosContext.get(`/goods-services`, config)
            return { data: data, status: status }
        } catch (error) {
            return { data: null, error: error }
        }
    },
}