import { Button, Card, Col, Divider, Modal, Row, Space, Typography } from "antd"
import { uniq } from "lodash-es"
import { FunctionComponent, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { CategoryModel } from "../../models/CategoryModel"
import { 출원방법타입 } from "../../types/출원방법타입"
import NumberUtility from "../../utils/NumberUtility"

interface IProps {
    open: boolean
    name: string
    alphabet?: string
    categories: Array<CategoryModel.ITrademarkCategoryFlattenModel>
    secondaryCodes?: Array<string>
    ratio: number
    onSelected: (type: 출원방법타입.출원방법, codes: Array<string>) => void
    onClosed: () => void
}

export const 출원방법선택Modal: FunctionComponent<IProps> = (props) => {
    const {
        open,
        name,
        alphabet,
        categories,
        secondaryCodes,
        ratio,
        onSelected,
        onClosed
    } = props

    const navigate = useNavigate()

    const [codes, setCodes] = useState<Array<string>>([])
    const [items] = useState<Array<{
        type: 출원방법타입.출원방법,
        ratio: number,
        price: number,
        display: string,
        descriptions: Array<{ text: string, isHighlight?: boolean }>,
        etc?: Array<string>
    }>>([
        {
            type: 출원방법타입.출원방법.직접출원,
            price: 0,
            display: '직접 출원',
            ratio: 70,
            descriptions: [
                { text: '출원 프로세스 튜토리얼 제공', },
            ]
        },
        {
            type: 출원방법타입.출원방법.바로출원,
            price: 18000,
            display: '바로 출원',
            ratio: 70,
            descriptions: [
                { text: '사무소 출원', },
                { text: '상표 출원 관리', },
            ],
            etc: [
                '특허청 관납료, VAT 별도'
            ]
        },
        {
            type: 출원방법타입.출원방법.간편출원,
            price: 48000,
            display: '간편 출원',
            ratio: 0,
            descriptions: [
                { text: '사무소 출원', },
                { text: '상표 출원 관리', },
                { text: '상표 전문 변리사 선임', isHighlight: true },
                { text: '상표 권리 범위 자문', isHighlight: true },
                { text: '추천 지정상품 제공', isHighlight: true },
            ],
            etc: [
                '특허청 관납료, VAT 별도',
                '출원 대행 수수료: 18,000원',
                '특허사무소 수수료: 30,000원 (별도납부)',
            ]
        },
        {
            type: 출원방법타입.출원방법.심화출원,
            price: 88000,
            display: '심화 출원',
            ratio: 0,
            descriptions: [
                { text: '사무소 출원', },
                { text: '상표 출원 관리', },
                { text: '상표 전문 변리사 선임', isHighlight: true },
                { text: '상표 권리 범위 자문', isHighlight: true },
                { text: '추천 지정상품 제공', isHighlight: true },
                { text: '등록 가능성 조사 및 보고서 제공', isHighlight: true },
                { text: '등록 가능성 기반 상표 출원 전략 제공', isHighlight: true },
            ],
            etc: [
                '특허청 관납료, VAT 별도',
                '출원 대행 수수료: 18,000원',
                '특허사무소 수수료: 70,000원 (별도납부)',
            ]
        },
    ])
    const [activeType, setActiveType] = useState<출원방법타입.출원방법>(
        items.filter(v => v.ratio <= ratio)[0].type
    )

    useEffect(() => {
        if (secondaryCodes) {
            setCodes([...uniq([...categories.map(c => c.codes[0]!), ...secondaryCodes])])
        } else {
            setCodes([...uniq([...categories.map(c => c.codes[0]!)])])
        }
    }, [secondaryCodes])

    const Footer = () => {
        return (
            <Row gutter={[16, 0]} style={{ paddingTop: 40, }} justify={'center'}>
                <Col span={12}>
                    <Button
                        block
                        className="primary"
                        disabled={codes.length <= 0 || !activeType}
                        onClick={() => {
                            switch (`${activeType}`) {
                                case '1':
                                    navigate('/direct/trademarks')
                                    break
                                case '2':
                                    onSelected(출원방법타입.출원방법.바로출원, codes)
                                    break
                                case '3':
                                    onSelected(출원방법타입.출원방법.간편출원, codes)
                                    break
                                case '4':
                                    onSelected(출원방법타입.출원방법.심화출원, codes)
                                    break
                            }
                        }}
                    >
                        출원하기
                    </Button>
                </Col>
            </Row>
        )
    }

    return (
        <Modal
            width={'80%'}
            open={open}
            className="gateway"
            destroyOnClose={true}
            footer={<Footer />}
            bodyStyle={{
                maxWidth: 600,
            }}
            onCancel={() => { onClosed() }}
        >
            <Row gutter={[24, 24]}>
                <Col span={24}>
                    <Space direction="vertical">
                        <Typography.Text
                            strong
                            style={{
                                fontSize: 20,
                            }}
                        >
                            {`${name} ${alphabet ? `(${alphabet})` : ''}`}
                        </Typography.Text>
                        <Space>
                            {codes.map((c, idx: number) => {
                                return (
                                    <Typography.Text
                                        key={idx}
                                        style={{
                                            fontSize: 18,
                                        }}
                                    >
                                        {`${c}류${idx === (codes.length - 1) ? '' : ', '}`}
                                    </Typography.Text>
                                )
                            })}
                        </Space>
                    </Space>
                    <Divider />
                </Col>
                <Col span={24}>
                    <Typography.Text
                        strong
                        style={{
                            fontSize: 22,
                        }}
                    >
                        {`출원 방법 선택`}
                    </Typography.Text>
                    <Row style={{ marginTop: 16, }}>
                        <Col span={24}>
                            <Row
                                gutter={[8, 0]}
                                justify={items.filter(v => v.ratio <= ratio).length === 4 ? "space-between" : "start"}
                            >
                                {items
                                    .filter(v => v.ratio <= ratio)
                                    .map((i) => {
                                        const isActivated = activeType === i.type

                                        return (
                                            <Col
                                                key={i.type}
                                                span={6}
                                            >
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        width: '100%',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        backgroundColor: isActivated ? '#36AFD9' : '#fff',
                                                        minHeight: 40,
                                                        textAlign: 'center',
                                                        border: '1px solid #eee',
                                                        borderBottom: 'none',
                                                        borderRadius: 8,
                                                        borderBottomRightRadius: 0,
                                                        borderBottomLeftRadius: 0,
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={() => {
                                                        setActiveType(i.type)
                                                    }}
                                                >
                                                    <Typography.Text
                                                        style={{
                                                            fontSize: 18,
                                                            color: isActivated ? '#fff' : '#000',
                                                        }}
                                                    >
                                                        {i.display}
                                                    </Typography.Text>
                                                </div>
                                            </Col>
                                        )
                                    })}
                            </Row>
                        </Col>
                        <Col span={24}>
                            {items
                                .filter(i => i.type === activeType)
                                .map((t) => {
                                    return (
                                        <Card
                                            key={t.type}
                                            style={{
                                                borderTopLeftRadius: 0,
                                                borderTopRightRadius: 0,
                                            }}
                                        >
                                            <Row gutter={[16, 16]}>
                                                <Col
                                                    span={24}
                                                >
                                                    <Space
                                                        size={6}
                                                        style={{
                                                            width: '100%',
                                                            textAlign: 'center',
                                                            justifyContent: 'center',
                                                            borderBottom: '1px solid #eee',
                                                            paddingBottom: 14,
                                                        }}
                                                    >
                                                        <Typography.Text
                                                            strong
                                                            style={{
                                                                fontSize: 16,
                                                            }}
                                                        >
                                                            {t.display}
                                                        </Typography.Text>
                                                        <Typography.Text
                                                            style={{
                                                                fontSize: 16,
                                                            }}
                                                        >
                                                            {`(${NumberUtility.comma(t.price)}원)`}
                                                        </Typography.Text>
                                                    </Space>
                                                </Col>
                                                <Col span={24}>
                                                    <Space
                                                        direction="vertical"
                                                        style={{
                                                            width: '100%',
                                                            textAlign: 'center',
                                                            paddingTop: 12,
                                                            paddingBottom: 12,
                                                            border: '1px solid #36AFD9'
                                                        }}
                                                    >
                                                        {t.descriptions.map((r) => {
                                                            return (
                                                                <Typography.Text
                                                                    key={r.text}
                                                                    style={{
                                                                        // color: '#FFF',
                                                                        color: r.isHighlight ? '#36AFD9' : '#000',
                                                                        fontWeight: r.isHighlight ? 700 : 400
                                                                    }}
                                                                >
                                                                    {r.text}
                                                                </Typography.Text>
                                                            )
                                                        })}
                                                    </Space>
                                                </Col>
                                                {t.etc && (
                                                    <Col span={24}>
                                                        <Space
                                                            size={4}
                                                            direction="vertical"
                                                            style={{
                                                                width: '100%',
                                                            }}
                                                        >
                                                            {t.etc.map((r) => {
                                                                return (
                                                                    <Typography.Text
                                                                        key={r}
                                                                    >
                                                                        {r}
                                                                    </Typography.Text>
                                                                )
                                                            })}
                                                        </Space>
                                                    </Col>
                                                )}
                                            </Row>
                                        </Card>
                                    )
                                })
                            }
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Modal>
    )
}